import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "AnemoiWalletWeb",
    component: () => import("@/view/AnemoiWalletWeb.vue"),
  },
  {
    path: "/:address",
    name: "AnemoiWalletWeb_Referrer",
    component: () => import("@/view/AnemoiWalletWeb.vue"),
  },
  {
    path: "/admin",
    name: "AnemoiAdminWeb",
    component: () => import("@/view/AnemoiAdminWeb.vue"),
  },
  {
    path: "/v2",
    name: "AnemoiWalletWeb_v2",
    component: () => import("@/view/AnemoiWalletWeb_v2.vue"),
  },
  {
    path: "/v2_admin",
    name: "AnemoiAdminWeb_v2",
    component: () => import("@/view/AnemoiAdminWeb_v2.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
