import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import axios from "axios";

require("./assets/css/style.css");

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const i18n = createI18n({
  locale: "en",
  messages: {
    ko: {
      // Common Text
      CommonWallet: "지갑",
      CommonLockup: "락업",
      CommonPresale: "프리세일",
      CommonStaking: "스테이킹",
      CommonDividend: "배당",
      CommonSwap: "스왑",
      CommonAmount: "수량",
      CommonWithdrawAmount: "출금 수량",
      CommonWithdrawableAmount: "출금 예상 수량",
      CommonRewardAmount: "보상 수량",
      CommonStartDate: "시작일",
      CommonEndDate: "종료일",
      CommonNoList: "목록이 없습니다.",
      CommonList: "대",
      CommonReady: "준비중",
      CommonStartAt: "시작까지",
      CommonAmkSwapAmount: "AMK 스왑 수량",

      // Wallet Text
      CommonMyWallet: "내 지갑",
      CommonMyAddress: "내 계정",
      CommonAddress: "주소",
      CommonCopyAddress: "주소 복사",
      CommonShareLink: "링크 공유",
      CommonSend: "전송",
      CommonMore: "더보기",

      // Lockup Text
      CommonNumber: "번호",
      CommonWithdrawCount: "출금 횟수",
      CommonStatus: "상태",
      CommonLocked: "락업",
      CommonRelease: "해제",
      CommonStop: "정지",
      CommonLockupIntroTitleText: "AMK를 락업하고 보상을 받으세요!",
      CommonLockupPlease: "락업을 진행해주세요.",
      CommonLockupCompleted: "락업 완료",
      CommonLockupStop: "락업 정지",

      // Presale Text
      CommonBuyToken: "토큰 구매",
      CommonMin: "최소",
      CommonMax: "최대",

      // Staking Text
      CommonMyStaking: "내 스테이킹",
      CommonType: "타입",
      CommonProgress: "진행",
      CommonCompleted: "완료",
      CommonPaused: "일시정지",
      CommonSlots: "현재 / 한도",
      CommonDuration: "기간",
      CommonDailyPayoutQuantity: "일일 지급 수량",
      CommonStakingQuantity: "스테이킹 수량",
      CommonTotalReward: "총 보상",
      CommonDays: "일",
      CommonTotalAmount: "총액",
      CommonReferrerTotalAmount: "추천인 총액",
      CommonWithdraw: "출금",

      // Dividend Text
      CommonMyDividend: "내 배당",
      CommonDepositedAmkTokens: "배당풀 총량",
      CommonExpectedProfit: "예상 수익",
      CommonJoinDividend: "배당 참여",
      CommonDividendProject: "배당 프로젝트",
      CommonPcmCooling: "PCM 축냉 에어컨",
      CommonExpectedDividend: "배당풀 스테이킹 보상",
      CommonDepositYour:
        "계약에 AMK 토큰을 예치하고 배당 날짜까지 보유하면 배당 대상이 됩니다. 배당에 참여하면 스테이킹 보상을 받을 수 있습니다.",
      CommonDividendInfo: "배당 정보",
      CommonDividendDesc1: "스테이킹 및 보상",
      CommonDividendDesc2: "배당 컨트랙트에 예치하면 스테이킹이 진행됩니다.",
      CommonDividendDesc3:
        "스테이킹 종료 후에만 스테이킹 보상과 예치한 수량을 수령할 수 있습니다.",
      CommonDividendDesc4: "배당 참여 조건",
      CommonDividendDesc5:
        "배당 대상이 되려면 이름과 생년월일을 입력해야 합니다.",
      CommonDividendDesc6:
        "이름과 생년월일을 입력하지 않으면 배당 대상에서 제외됩니다.",
      CommonDividendDesc7:
        "단, 스테이킹 보상과는 관계가 없습니다. 입력하지 않더라도 스테이킹 보상은 받을 수 있습니다.",
      CommonDividendDesc8: "배당 참여 마감",
      CommonDividendDesc9: "배당 참여는 종료일 전까지 가능합니다.",
      CommonDividendDesc10:
        "배당 대상은 배당 컨트랙트에 예치한 사용자에 한정됩니다.",
      CommonDividendDesc11: "보상 및 유의사항",
      CommonDividendDesc12:
        "보상 수량은 예상치로, 다른 사용자가 추가로 참여할수록 보상 수량은 줄어듭니다.",
      CommonDividendDesc13:
        "보상은 스테이킹된 양과 기간에 비례하여 지급됩니다.",
      CommonDividendDesc14: "추가사항",
      CommonDividendDesc15:
        "스테이킹 중간에 보상이나 예치를 출금할 수 없습니다.",
      CommonDepositAmount: "예치 수량",
      CommonMyShare: "내 지분율",
      CommonExpectedReward: "예상 보상",
      CommonMyBalance: "내 잔고",
      CommonUntilEnd: "종료까지",
      CommonUntilEnd2: "결산 완료 후 지급될 예정입니다.",
      CommonDividendIntro1: "아네모이 AMK는 증권형토큰 입니다.",
      CommonDividendIntro2: "향후 예정된 배당금 지급을 위해",
      CommonDividendIntro3: "고객님의 이름(본명)과 생년월일을 입력해야 합니다.",
      CommonDividendIntro4: "정확한 정보를 입력해주셔야",
      CommonDividendIntro5: "배당금이 지급 됩니다.",
      CommonDividendIntro6:
        "배당금을 원하지 않으시면 입력을 하지않으신 상태로 확인 버튼을 눌러주세요.",
      CommonDividendName: "이름(본명)을 입력해주세요.",
      CommonDividendBirth: "생년월일(8자리)을 입력해주세요.",
      CommonDividendCheck: "다시 보지 않기",
      CommonDividendMax: "전체",
      CommonDividendEndDate: "배당 종료 날짜",
      CommonDividendInputEndDate: "배당 종료 날짜를 입력해주세요.",
      CommonDividendNotice: "공지",
      CommonDividendEnded: "현재 진행중인 배당이 없습니다.",
      CommonDividendIng: "진행중",
      CommonDividendBlacklistedAddress: "출금이 제한된 지갑입니다. 재단에 문의해주세요.",

      // Common Set Text
      CommonSetLockup: "락업 설정",
      CommonSetPresale: "프리세일 설정",
      CommonSetStaking: "스테이킹 설정",
      CommonReferrerRegistration: "추천인 등록",
      CommonReferrer: "추천인",
      CommonConfirm: "확인",
      CommonCancel: "취소",

      // Staking Information
      stakingInformation: "스테이킹 정보",
      stakingInfoTitle01: "수수료 공제",
      stakingInfoText01: "인출 시 5%의 수수료가 공제됩니다.",
      stakingInfoTitle02: "스테이킹 제한",
      stakingInfoText02: "활성 스테이킹은 하나만 허용됩니다.",
      stakingInfoTitle03: "회원 요구 사항",
      stakingInfoText03: "등록 후에만 스테이킹이 가능합니다.",
      stakingInfoTitle04: "보상 구조",
      stakingInfoText04:
        "추천 보상은 보상의 양을 늘리는 것이 아닌 스테이킹 전체 보상을 당겨서 받는 개념입니다.",
      stakingInfoTitle05: "추천 보상",
      stakingInfoText05:
        "나를 추천한 사람이 스테이킹하면 다음과 같은 보상을 받을 수 있습니다.",
      stakingInfoTitle06: "1차 추천",
      stakingInfoText06: "스테이킹 금액의 7%",
      stakingInfoTitle07: "2차 추천",
      stakingInfoText07: "스테이킹 금액의 5%",
      stakingInfoTitle08: "3차 추천",
      stakingInfoText08: "스테이킹 금액의 3%",
      stakingInfoTitle09: "4차 추천",
      stakingInfoText09: "스테이킹 금액의 2%",
      stakingInfoTitle10: "5차 추천",
      stakingInfoText10: "스테이킹 금액의 1%",
      stakingInfoTitle11: "6차 추천",
      stakingInfoText11: "스테이킹 금액의 1%",
      stakingInfoTitle12: "7차 추천",
      stakingInfoText12: "스테이킹 금액의 1%",

      // Page State Wallet
      walletWrapTextFront: "당신의",
      walletWrapTextBack: "로 더 많은 가치를 발견하세요",
      walletWrapTextPoint: "AMK",

      // Page State Presale
      presaleWrapTextFrontStart: "프리세일이",
      presaleWrapTextPointStart: "종료되었습니다",
      presaleWrapTextFrontLive: "프리세일이",
      presaleWrapTextPointLive: "종료되었습니다",

      // Page State Swap
      swapIntroTitleText: "AMK 스왑",
      swapIntroText1: "한정된 기간 동안만 진행되는 특별한 스왑!",
      swapIntroText2: "지금 참여하고 혜택을 놓치지 마세요!",
      swapIntroText3:
        "스왑된 물량은 반드시 2차 배당 컨트랙트에 예치해야합니다.",
      swapReadyText: "AMK 스왑을 준비하고 있습니다.",
      swapWhiteListText: "화이트리스트에 등록된 지갑만 참여 가능합니다.",
      swapCaptionText:
        "1차 배당 컨트랙트의 해제는 3월 8일부터 3일간 진행됩니다. 이 기간 이후에는 인출이 불가능하니 기한 내에 반드시 인출을 완료해주세요.",
      swapNoShow: "다시 보지 않기",

      // Alert Success Message
      alertSuccessChangeAdmin: "관리자가 변경되었습니다.",
      alertSuccessAddLockup: "락업이 추가되었습니다.",
      alertSuccessSetFeeRecipient: "수수료 수취인이 변경되었습니다.",
      alertSuccessSetRecipient: "수령인이 변경되었습니다.",
      alertSuccessSetFeeRate: "수수료 비율이 변경되었습니다.",
      alertSuccessSetPresale: "프리세일 설정이 완료되었습니다.",
      alertSuccessSetStakingLimit: "스테이킹 제한이 변경되었습니다.",
      alertSuccessSendAmount: "금액이 전송되었습니다.",
      alertSuccessBuyToken: "토큰이 구매되었습니다.",
      alertSuccessWithdrawFunds: "자금이 인출되었습니다.",
      alertSuccessWithdrawToken: "토큰이 인출되었습니다.",
      alertSuccessEndPresale: "프리세일이 종료되었습니다.",
      alertSuccessStartStaking: "스테이킹이 시작되었습니다.",
      alertSuccessLockupStart: "락업이 시작되었습니다.",
      alertSuccessLockupRelease: "락업이 해제되었습니다.",
      alertSuccessLockupStop: "락업이 중지되었습니다.",
      alertSuccessChangeStakingStatus: "스테이킹 상태가 변경되었습니다.",
      alertSuccessCopyAddress: "주소가 복사되었습니다.",
      alertSuccessCopyLink: "링크가 복사되었습니다.",
      alertSuccessLockupData: "락업 데이터가 변경되었습니다.",
      alertSuccessCompleteDividend: "배당에 참여되었습니다.",
      alertSuccessClaimDividend: "보상을 수령하였습니다.",
      alertSuccessEndDividend: "배당 종료 날짜가 설정되었습니다.",
      alertSuccessEndStaking: "스테이킹이 종료되었습니다.",
      alertSuccessRegister: "정보가 정상적으로 등록되었습니다.",
      alertSuccessSwapWithdrawAmk: "AMK가 인출되었습니다.",
      alertSuccessSwapWithdrawUsdt: "USDT가 인출되었습니다.",
      alertSuccessEndDate: "종료 날짜가 설정되었습니다.",
      alertSuccessPrice: "가격이 설정되었습니다.",
      alertSuccessSwapToken: "토큰이 스왑되었습니다.",

      // Alert Error Message
      alertErrorConnectWallet: "지갑을 연결해주세요.",
      alertErrorUserReject: "사용자가 요청을 거부했습니다.",
      alertErrorRequestFailed: "요청이 실패했습니다. 나중에 다시 시도해주세요.",
      alertErrorFeeRate:
        "수수료 비율은 0 에서 100 사이로만 입력할 수 있습니다.",
      alertErrorChainId: "체인 변경에 실패했습니다. 나중에 다시 시도해주세요.",
      alertErrorEnterAddress: "주소를 입력해주세요.",
      alertErrorEnterAmount: "수량을 입력해주세요.",
      alertErrorEnterQuantity: "수량을 입력해주세요.",
      alertErrorInvalidAddress: "주소가 유효하지 않습니다.",
      alertErrorInvalidAmount: "수량이 유효하지 않습니다.",
      alertErrorInvalidDate: "날짜가 유효하지 않습니다.",
      alertErrorTransactionFailed:
        "거래가 실패했습니다. 나중에 다시 시도해주세요.",
      alertErrorMaximumStaking:
        "총 3개의 스테이킹만 허용되며, 활성 스테이킹은 하나만 허용됩니다.",
      alertErrorSetPresaleValue: "설정할 값을 입력해주세요.",
      alertErrorSetPresaleMinPurchase01:
        "최소 수량이 현재 입력된 최대 수량을 초과합니다.",
      alertErrorSetPresaleMinPurchase02:
        "최소 수량이 현재 설정된 최대 수량을 초과합니다.",
      alertErrorSetPresaleMaxPurchase01:
        "최대 수량이 현재 입력된 최소 수량보다 작습니다.",
      alertErrorSetPresaleMaxPurchase02:
        "최대 수량이 현재 설정된 최소 수량보다 작습니다.",
      alertErrorSetPresalePrice: "가격을 입력해주세요.",
      alertErrorNotMember: "입력한 추천인 주소는 회원이 아닙니다.",
      alertErrorSetStakingType: "타입을 선택해주세요.",
      alertErrorSetStakingTotalLimit: "수량을 입력해주세요.",
      alertErrorSignUp:
        "회원가입 중 오류가 발생했습니다. 나중에 다시 시도해주세요",
      alertErrorInsufficientBalance: "잔액이 부족합니다.",
      alertErrorMinimumQuantity: "입력한 수량이 최소 수량보다 작습니다.",
      alertErrorMaximumQuantity: "입력한 수량이 최대 수량보다 큽니다.",
      alertErrorTimeOut:
        "요청 시간이 초과되었습니다. 나중에 다시 시도해주세요.",
      alertErrorNotStakingDate:
        "스테이킹 시작 시간(GMT) : 2024-04-20 AM 12:00:00",
      alertErrorUnknown:
        "알 수 없는 오류가 발생했습니다. 나중에 다시 시도해주세요.",
      alertErrorAlreadyStaking: "이미 스테이킹 중입니다.",
      alertErrorUserNotFound: "사용자를 찾을 수 없습니다.",
      alertErrorNotAdmin: "관리자가 아닙니다.",
      alertErrorNotDividend: "현재 배당이 진행되고 있지않습니다.",
      alertErrorEnterIndex: "번호를 입력해주세요.",
      alertErrorEndStaking: "해당 스테이킹의 진행 개수가 최대입니다.",
      alertErrorNotStakingAddress: "승인된 주소만 1만개 스테이킹이 가능합니다.",
      alertErrorEnterNameBirth: "이름과 생년월일을 모두 입력해주세요.",
      alertErrorEnterBirth: "생년월일을 8자리로 입력해주세요.",
      alertErrorNoUnstakeToken: "출금 가능한 수량이 없습니다.",
      alertErrorEnterStartDate: "시작일을 입력해주세요.",
      alertErrorStartDate: "시작일은 현재 날짜 이후여야 합니다.",
      alertErrorRewardCountZero: "보상 수량은 0보다 크게 입력해주세요.",
      alertErrorSoldAmountSwap: "현재 남은 스왑 수량이 없습니다.",

      // Staking Alert Error Message
      alertErrorClaimOnce: "UTC 자정 이후 하루에 한 번만 출금할 수 있습니다.",
      // alertErrorOnlyActiveClaim: "보상을 청구하려면 스테이킹이 활성화되어야합니다.",
      alertErrorOnlyActiveClaim: "출금 가능한 수량이 없습니다.",
      alertErrorSearchAfterStaking: "스테이킹 후 검색 가능합니다.",
      alertErrorNotEnoughBalance: "잔액이 부족합니다.",
      alertErrorAmountZero: "수량은 0보다 크게 입력해주세요.",
      alertErrorInvalidPrice: "가격이 유효하지 않습니다.",
    },
    en: {
      // Common Text
      CommonWallet: "Wallet",
      CommonLockup: "Lockup",
      CommonPresale: "Presale",
      CommonStaking: "Staking",
      CommonDividend: "Dividend",
      CommonSwap: "Swap",
      CommonAmount: "Amount",
      CommonWithdrawAmount: "Withdraw Amount",
      CommonWithdrawableAmount: "Estimated withdrawal amount",
      CommonRewardAmount: "Reward Amount",
      CommonStartDate: "Start Date",
      CommonEndDate: "End Date",
      CommonNoList: "No List.",
      CommonList: "Generation",
      CommonReady: "Ready",
      CommonStartAt: "Start At",
      CommonAmkSwapAmount: "AMK SWAP Amount",

      // Wallet Text
      CommonMyWallet: "My Wallet",
      CommonMyAddress: "My Address",
      CommonAddress: "Address",
      CommonCopyAddress: "Copy Address",
      CommonShareLink: "Share Link",
      CommonSend: "Send",
      CommonMore: "More",

      // Lockup Text
      CommonNumber: "Number",
      CommonWithdrawCount: "Withdraw Count",
      CommonStatus: "Status",
      CommonLocked: "Locked",
      CommonRelease: "Released",
      CommonStop: "Stop",
      CommonLockupIntroTitleText: "Lock up AMK and receive rewards!",
      CommonLockupPlease: "Please proceed with the lockup.",
      CommonLockupCompleted: "Lockup Completed",
      CommonLockupStop: "Lockup Stop",

      // Presale Text
      CommonBuyToken: "Buy Token",
      CommonMin: "Min",
      CommonMax: "Max",

      // Staking Text
      CommonMyStaking: "My Staking",
      CommonType: "Type",
      CommonProgress: "Progress",
      CommonCompleted: "Completed",
      CommonPaused: "Paused",
      CommonSlots: "Current / Limit",
      CommonDuration: "Duration",
      CommonDailyPayoutQuantity: "Daily Payout Quantity",
      CommonStakingQuantity: "Staking Quantity",
      CommonTotalReward: "Total Reward",
      CommonDays: "Days",
      CommonTotalAmount: "Total Amount",
      CommonReferrerTotalAmount: "Referrer Total Amount",
      CommonWithdraw: "Withdraw",

      // Dividend Text
      CommonMyDividend: "My Dividend",
      CommonDepositedAmkTokens: "Total Dividend Pool",
      CommonExpectedProfit: "Expected Profit",
      CommonJoinDividend: "Join Dividend",
      CommonDividendProject: "Dividend Project",
      CommonPcmCooling: "PCM Air Conditional",
      CommonExpectedDividend: "Dividend Pool Staking Rewards",
      CommonDepositYour:
        "Deposit your AMK tokens into the contract and hold until the dividend date to be eligible for dividends. Participate to receive staking rewards.",
      CommonDividendInfo: "Dividend Information",
      CommonDividendDesc1: "Staking and Reward",
      CommonDividendDesc2:
        "Depositing into the dividend contract will proceed with staking.",
      CommonDividendDesc3:
        "You can only receive staking rewards and the deposited amount after the staking ends.",
      CommonDividendDesc4: "Dividend Participation Conditions",
      CommonDividendDesc5:
        "To be eligible for dividends, you must enter your name and date of birth.",
      CommonDividendDesc6:
        "If you do not enter your name and date of birth, you will be excluded from the dividend.",
      CommonDividendDesc7:
        "However, this does not affect staking rewards. Even if you do not enter, you can receive staking rewards.",
      CommonDividendDesc8: "Dividend Participation Deadline",
      CommonDividendDesc9:
        "Dividend participation is available until the end date.",
      CommonDividendDesc10:
        "Dividend recipients are limited to users who have deposited into the dividend contract.",
      CommonDividendDesc11: "Rewards and Precautions",
      CommonDividendDesc12:
        "The reward amount is an estimate, and as more users participate, the reward amount decreases.",
      CommonDividendDesc13:
        "Rewards are paid proportionally to the staked amount and period.",
      CommonDividendDesc14: "Additional Information",
      CommonDividendDesc15:
        "Rewards or deposits cannot be withdrawn during staking.",
      CommonDepositAmount: "Deposit Amount",
      CommonMyShare: "My Share",
      CommonExpectedReward: "Expected Reward",
      CommonMyBalance: "My Balance",
      CommonUntilEnd: "Until the end of",
      CommonUntilEnd2: "Payment will be made after the settlement is complete.",
      CommonDividendIntro1: "Anemoi AMK is a security token.",
      CommonDividendIntro2: "For future dividend payments,",
      CommonDividendIntro3:
        "you must enter your name (real name) and date of birth.",
      CommonDividendIntro4: "You must enter accurate information",
      CommonDividendIntro5: "to receive dividends.",
      CommonDividendIntro6:
        "If you do not want dividends, press the confirmation button without entering.",
      CommonDividendName: "Please enter your name (real name).",
      CommonDividendBirth: "Please enter your date of birth (8 digits).",
      CommonDividendCheck: "Do not show again",
      CommonDividendMax: "Max",
      CommonDividendEndDate: "Dividend End Date",
      CommonDividendInputEndDate: "Please enter the dividend end date.",
      CommonDividendNotice: "Notice",
      CommonDividendEnded: "There is no current dividend in progress.",
      CommonDividendIng: "In Progress",
      CommonDividendBlacklistedAddress: "Withdrawal is restricted. Please contact the foundation.",

      // Common Set Text
      CommonSetLockup: "Set Lockup",
      CommonSetPresale: "Set Presale",
      CommonSetStaking: "Set Staking",
      CommonReferrerRegistration: "Referrer Registration",
      CommonReferrer: "Referrer",
      CommonConfirm: "Confirm",
      CommonCancel: "Cancel",

      // Staking Information
      stakingInformation: "Staking Information",
      stakingInfoTitle01: "Fee Disclosure",
      stakingInfoText01: "5% fee will be deducted upon claiming.",
      stakingInfoTitle02: "Staking Limit",
      stakingInfoText02:
        "Only one active staking is allowed. After termination, up to 3 additional stakings can be added.",
      stakingInfoTitle03: "Membership Requirement",
      stakingInfoText03: "Staking is only possible after registration.",
      stakingInfoTitle04: "Reward Structure",
      stakingInfoText04:
        "Rewards will be distributed daily over 360 days. The concept of referral rewards is about claiming rewards early rather than increasing the amount of rewards.",
      stakingInfoTitle05: "Referral Rewards",
      stakingInfoText05:
        "If the person who referred you stakes, you can receive the following rewards.",
      stakingInfoTitle06: "1st Referral",
      stakingInfoText06: "7% of their staking amount",
      stakingInfoTitle07: "2nd Referral",
      stakingInfoText07: "5% of their staking amount",
      stakingInfoTitle08: "3rd Referral",
      stakingInfoText08: "3% of their staking amount",
      stakingInfoTitle09: "4th Referral",
      stakingInfoText09: "2% of their staking amount",
      stakingInfoTitle10: "5th Referral",
      stakingInfoText10: "1% of their staking amount",
      stakingInfoTitle11: "6th Referral",
      stakingInfoText11: "1% of their staking amount",
      stakingInfoTitle12: "7th Referral",
      stakingInfoText12: "1% of their staking amount",

      // Page State Wallet
      walletWrapTextFront: "Discover More Value with Your",
      walletWrapTextBack: "Here",
      walletWrapTextPoint: "AMK",

      // Page State Presale
      presaleWrapTextFrontStart: "Presale has",
      presaleWrapTextPointStart: "Finished",
      presaleWrapTextFrontLive: "Presale has",
      presaleWrapTextPointLive: "Finished",

      // Page State Swap
      swapIntroTitleText: "AMK SWAP",
      swapIntroText1:
        "A special swap that is only available for a limited time!",
      swapIntroText2: "Join now and don't miss out on the benefits!",
      swapIntroText3:
        "The swapped quantity must be deposited into the 2nd dividend contract.",
      swapReadyText: "The AMK SWAP is currently being prepared.",
      swapWhiteListText:
        "Only wallets registered on the whitelist can participate.",
      swapCaptionText:
        "The release of the 1st dividend contract will take place from March 8th for 3 days. After this period, withdrawals will not be possible, so please make sure to complete the withdrawal within the deadline.",
      swapNoShow: "Do not show again",

      // Alert Success Message
      alertSuccessChangeAdmin: "The administrator has been changed.",
      alertSuccessAddLockup: "Lockup has been added.",
      alertSuccessSetFeeRecipient: "Fee recipient has been changed.",
      alertSuccessSetRecipient: "Recipient has been changed.",
      alertSuccessSetFeeRate: "Fee rate has been changed.",
      alertSuccessSetPresale: "Presale settings have been completed.",
      alertSuccessSetStakingLimit: "Staking limit has been changed.",
      alertSuccessSendAmount: "Amount has been sent.",
      alertSuccessBuyToken: "Token has been purchased.",
      alertSuccessWithdrawFunds: "Funds have been withdrawn.",
      alertSuccessWithdrawToken: "Token has been withdrawn.",
      alertSuccessEndPresale: "Presale has ended.",
      alertSuccessStartStaking: "Staking has started.",
      alertSuccessLockupStart: "Lockup has been started.",
      alertSuccessLockupRelease: "Lockup has been released.",
      alertSuccessLockupStop: "Lockup has been stopped.",
      alertSuccessChangeStakingStatus: "Staking status has been changed.",
      alertSuccessCopyAddress: "Address has been copied.",
      alertSuccessCopyLink: "Link has been copied.",
      alertSuccessLockupData: "Lockup data has been changed.",
      alertSuccessCompleteDividend: "Dividend has been participated.",
      alertSuccessClaimDividend: "Rewards have been claimed.",
      alertSuccessEndDividend: "Dividend end date has been set.",
      alertSuccessEndStaking: "Staking has ended.",
      alertSuccessRegister: "Information has been successfully registered.",
      alertSuccessSwapWithdrawAmk: "AMK has been withdrawn.",
      alertSuccessSwapWithdrawUsdt: "USDT has been withdrawn.",
      alertSuccessEndDate: "End date has been set.",
      alertSuccessPrice: "Price has been set.",
      alertSuccessSwapToken: "Token has been swapped.",

      // Alert Error Message
      alertErrorConnectWallet: "Please connect your wallet.",
      alertErrorUserReject: "User rejected the request.",
      alertErrorRequestFailed:
        "The request has failed. Please try again later.",
      alertErrorFeeRate: "Fee rate can only be entered between 0 and 100.",
      alertErrorChainId:
        "The chain modification failed. Please try again later.",
      alertErrorEnterAddress: "Please enter the address.",
      alertErrorEnterAmount: "Please enter the amount.",
      alertErrorEnterQuantity: "Please enter the quantity.",
      alertErrorInvalidAddress: "The address is invalid.",
      alertErrorInvalidAmount: "The amount is invalid.",
      alertErrorInvalidDate: "The date is invalid.",
      alertErrorTransactionFailed:
        "Transaction failed. Please try again later.",
      alertErrorMaximumStaking:
        "Maximum of 3 stakes in total allowed, only one active stake allowed.",
      alertErrorSetPresaleValue: "Please enter the value to set.",
      alertErrorSetPresaleMinPurchase01:
        "The minimum quantity exceeds the current maximum quantity entered.",
      alertErrorSetPresaleMinPurchase02:
        "The minimum quantity exceeds the currently set maximum quantity.",
      alertErrorSetPresaleMaxPurchase01:
        "The maximum quantity entered is less than the currently set minimum quantity.",
      alertErrorSetPresaleMaxPurchase02:
        "The maximum quantity entered is less than the minimum quantity.",
      alertErrorSetPresalePrice: "Please enter the price.",
      alertErrorNotMember: "The entered referral address is not a member.",
      alertErrorSetStakingType: "Please select the type.",
      alertErrorSetStakingTotalLimit: "Please enter the total limit.",
      alertErrorSignUp:
        "An error occurred during the registration process. Please try again later",
      alertErrorInsufficientBalance: "Insufficient balance.",
      alertErrorMinimumQuantity:
        "The entered quantity is less than the minimum quantity.",
      alertErrorMaximumQuantity:
        "The entered quantity is greater than the maximum quantity.",
      alertErrorTimeOut: "Request timed out. Please try again later.",
      alertErrorNotStakingDate:
        "Staking Start Time(GMT) : 2024-04-20 AM 12:00:00",
      alertErrorUnknown: "An unknown error occurred. Please try again later.",
      alertErrorAlreadyStaking: "You are already staking.",
      alertErrorUserNotFound: "User not found.",
      alertErrorNotAdmin: "You are not an administrator.",
      alertErrorNotDividend: "Dividend is not currently in progress.",
      alertErrorEnterIndex: "Please enter the number.",
      alertErrorEndStaking:
        "The progress count of the current staking has reached the maximum value.",
      alertErrorNotStakingAddress:
        "Only 10,000 staking is possible with approved addresses.",
      alertErrorEnterNameBirth: "Please enter both name and date of birth.",
      alertErrorEnterBirth: "Please enter your date of birth in 8 digits.",
      alertErrorNoUnstakeToken: "There is no amount available for withdrawal.",
      alertErrorEnterStartDate: "Please enter the start date.",
      alertErrorStartDate: "The start date must be after the current date.",
      alertErrorRewardCountZero: "The reward quantity must be greater than 0.",
      alertErrorSoldAmountSwap: "There is no remaining swap quantity.",

      // Staking Alert Error Message
      alertErrorClaimOnce:
        "Claim can only be made once a day after midnight UTC",
      // alertErrorOnlyActiveClaim: "Stake must be active to claim rewards",
      alertErrorOnlyActiveClaim: "There is no amount to withdraw.",
      alertErrorSearchAfterStaking: "Search is available after staking",
      alertErrorNotEnoughBalance: "Insufficient balance.",
      alertErrorAmountZero: "The amount must be greater than 0.",
      alertErrorInvalidPrice: "The price is invalid.",
    },
    zh: {
      // Common Text
      CommonWallet: "钱包",
      CommonLockup: "锁定",
      CommonPresale: "预售",
      CommonStaking: "质押",
      CommonDividend: "分红",
      CommonSwap: "交换",
      CommonAmount: "数量",
      CommonWithdrawAmount: "提取数量",
      CommonWithdrawableAmount: "预计提款金额",
      CommonRewardAmount: "奖励数量",
      CommonStartDate: "开始日期",
      CommonEndDate: "结束日期",
      CommonNoList: "没有列表。",
      CommonList: "代",
      CommonReady: "准备",
      CommonStartAt: "开始",
      CommonAmkSwapAmount: "AMK 交换数量",

      // Wallet Text
      CommonMyWallet: "我的钱包",
      CommonMyAddress: "我的地址",
      CommonAddress: "地址",
      CommonCopyAddress: "复制地址",
      CommonShareLink: "分享链接",
      CommonSend: "发送",
      CommonMore: "更多",

      // Lockup Text
      CommonNumber: "编号",
      CommonWithdrawCount: "提取次数",
      CommonStatus: "状态",
      CommonLocked: "锁定",
      CommonRelease: "释放",
      CommonStop: "停止",
      CommonLockupIntroTitleText: "锁定AMK并获得奖励！",
      CommonLockupPlease: "请继续锁定。",
      CommonLockupCompleted: "锁定已完成",
      CommonLockupStop: "锁定停止",

      // Presale Text
      CommonBuyToken: "购买代币",
      CommonMin: "最小",
      CommonMax: "最大",

      // Staking Text
      CommonMyStaking: "我的质押",
      CommonType: "类型",
      CommonProgress: "进度",
      CommonCompleted: "完成",
      CommonPaused: "暂停",
      CommonSlots: "当前 / 限制",
      CommonDuration: "持续时间",
      CommonDailyPayoutQuantity: "每日支付数量",
      CommonStakingQuantity: "质押数量",
      CommonTotalReward: "总奖励",
      CommonDays: "天",
      CommonTotalAmount: "总金额",
      CommonReferrerTotalAmount: "推荐人总金额",
      CommonWithdraw: "提取",

      // Dividend Text
      CommonMyDividend: "我的分红",
      CommonDepositedAmkTokens: "总分红池",
      CommonExpectedProfit: "预期利润",
      CommonJoinDividend: "加入分红",
      CommonDividendProject: "分红项目",
      CommonPcmCooling: "PCM冷却空调",
      CommonExpectedDividend: "分红池质押奖励",
      CommonDepositYour:
        "将您的AMK代币存入合同并保留到分红日期，以便获得分红资格。参与以获得质押奖励。",
      CommonDividendInfo: "分红信息",
      CommonDividendDesc1: "质押和奖励",
      CommonDividendDesc2: "存入分红合同将进行质押。",
      CommonDividendDesc3: "仅在质押结束后才能收取质押奖励和存入金额。",
      CommonDividendDesc4: "分红参与条件",
      CommonDividendDesc5: "要获得分红资格，您必须输入姓名和出生日期。",
      CommonDividendDesc6: "如果您不输入姓名和出生日期，您将被排除在分红之外。",
      CommonDividendDesc7:
        "但是，这不影响质押奖励。即使您不输入，也可以获得质押奖励。",
      CommonDividendDesc8: "分红参与截止日期",
      CommonDividendDesc9: "分红参与截止日期为结束日期。",
      CommonDividendDesc10: "分红接收者仅限于存入分红合同的用户。",
      CommonDividendDesc11: "奖励和注意事项",
      CommonDividendDesc12:
        "奖励金额是估计值，随着更多用户的参与，奖励金额会减少。",
      CommonDividendDesc13: "奖励按质押金额和期限比例支付。",
      CommonDividendDesc14: "额外信息",
      CommonDividendDesc15: "在质押期间无法提取奖励或存款。",
      CommonDepositAmount: "存款金额",
      CommonMyShare: "我的份额",
      CommonExpectedReward: "预期奖励",
      CommonMyBalance: "我的余额",
      CommonUntilEnd: "直到结束",
      CommonUntilEnd2: "结算完成后支付。",
      CommonDividendIntro1: "Anemoi AMK是一种证券代币。",
      CommonDividendIntro2: "为了未来的分红支付，",
      CommonDividendIntro3: "您必须输入您的姓名（真实姓名）和出生日期。",
      CommonDividendIntro4: "您必须输入准确的信息",
      CommonDividendIntro5: "以接收分红。",
      CommonDividendIntro6: "如果您不想要分红，请在不输入的情况下按确认按钮。",
      CommonDividendName: "请输入您的姓名（真实姓名）。",
      CommonDividendBirth: "请输入您的出生日期（8位数）。",
      CommonDividendCheck: "不再显示",
      CommonDividendMax: "最大",
      CommonDividendEndDate: "分红结束日期",
      CommonDividendInputEndDate: "请输入分红结束日期。",
      CommonDividendNotice: "通知",
      CommonDividendEnded: "当前没有进行中的分红。",
      CommonDividendIng: "进行中",
      CommonDividendBlacklistedAddress: "提款受限的钱包。请联系基金会。",

      // Common Set Text
      CommonSetLockup: "设置锁定",
      CommonSetPresale: "设置预售",
      CommonSetStaking: "设置质押",
      CommonReferrerRegistration: "推荐人注册",
      CommonReferrer: "推荐人",
      CommonConfirm: "确认",
      CommonCancel: "取消",

      // Staking Information
      stakingInformation: "质押信息",
      stakingInfoTitle01: "费用披露",
      stakingInfoText01: "索取时将扣除5%的费用。",
      stakingInfoTitle02: "质押限制",
      stakingInfoText02:
        "只允许一个活跃的质押。 解除后，最多可以添加3个额外的质押。",
      stakingInfoTitle03: "会员要求",
      stakingInfoText03: "注册后才能进行质押。",
      stakingInfoTitle04: "奖励结构",
      stakingInfoText04:
        "奖励将在360天内每天分配。 推荐奖励的概念是提前索取奖励而不是增加奖励数量。",
      stakingInfoTitle05: "推荐奖励",
      stakingInfoText05: "如果推荐您的人质押，您可以获得以下奖励。",
      stakingInfoTitle06: "第一推荐",
      stakingInfoText06: "其质押金额的7%",
      stakingInfoTitle07: "第二推荐",
      stakingInfoText07: "其质押金额的5%",
      stakingInfoTitle08: "第三推荐",
      stakingInfoText08: "其质押金额的3%",
      stakingInfoTitle09: "第四推荐",
      stakingInfoText09: "其质押金额的2%",
      stakingInfoTitle10: "第五推荐",
      stakingInfoText10: "其质押金额的1%",
      stakingInfoTitle11: "第六推荐",
      stakingInfoText11: "其质押金额的1%",
      stakingInfoTitle12: "第七推荐",
      stakingInfoText12: "其质押金额的1%",

      // Page State Wallet
      walletWrapTextFront: "发现更多价值与您的",
      walletWrapTextBack: "这里",
      walletWrapTextPoint: "AMK",

      // Page State Presale
      presaleWrapTextFrontStart: "预售已",
      presaleWrapTextPointStart: "结束",
      presaleWrapTextFrontLive: "预售已",
      presaleWrapTextPointLive: "结束",

      // Page State Swap
      swapIntroTitleText: "AMK SWAP",
      swapIntroText1: "只有在有限的时间内才能使用的特殊交换!",
      swapIntroText2: "现在加入，不要错过好处!",
      swapIntroText3: "交换的数量必须存入第二个分红合同。",
      swapReadyText: "AMK SWAP正在准备中。",
      swapWhiteListText: "只有在白名单上注册的钱包才能参与。",
      swapCaptionText:
        "第一个分红合同的释放将从3月8日持续3天。此期间之后，将无法提款，请务必在截止日期内完成提款。",
      swapNoShow: "不再显示",

      // Alert Success Message
      alertSuccessChangeAdmin: "管理员已更改。",
      alertSuccessAddLockup: "锁定已添加。",
      alertSuccessSetFeeRecipient: "费用接收人已更改。",
      alertSuccessSetRecipient: "接收人已更改。",
      alertSuccessSetFeeRate: "费率已更改。",
      alertSuccessSetPresale: "预售设置已完成。",
      alertSuccessSetStakingLimit: "质押限制已更改。",
      alertSuccessSendAmount: "金额已发送。",
      alertSuccessBuyToken: "代币已购买。",
      alertSuccessWithdrawFunds: "资金已提取。",
      alertSuccessWithdrawToken: "代币已提取。",
      alertSuccessEndPresale: "预售已结束。",
      alertSuccessStartStaking: "质押已开始。",
      alertSuccessLockupStart: "锁定已开始。",
      alertSuccessLockupRelease: "锁定已释放。",
      alertSuccessLockupStop: "锁定已停止。",
      alertSuccessChangeStakingStatus: "质押状态已更改。",
      alertSuccessCopyAddress: "地址已复制。",
      alertSuccessCopyLink: "链接已复制。",
      alertSuccessLockupData: "锁定数据已更改。",
      alertSuccessCompleteDividend: "已参与分红。",
      alertSuccessClaimDividend: "奖励已领取。",
      alertSuccessEndDividend: "分红结束日期已设置。",
      alertSuccessEndStaking: "质押已结束。",
      alertSuccessRegister: "信息已成功注册。",
      alertSuccessSwapWithdrawAmk: "AMK已提取。",
      alertSuccessSwapWithdrawUsdt: "USDT已提取。",
      alertSuccessEndDate: "结束日期已设置。",
      alertSuccessPrice: "价格已设置。",
      alertSuccessSwapToken: "Token has been swapped.",

      // Alert Error Message
      alertErrorConnectWallet: "请连接您的钱包。",
      alertErrorUserReject: "用户拒绝了请求。",
      alertErrorRequestFailed: "请求失败。请稍后再试。",
      alertErrorFeeRate: "费率只能在0到100之间输入。",
      alertErrorChainId: "链修改失败。请稍后再试。",
      alertErrorEnterAddress: "请输入地址。",
      alertErrorEnterAmount: "请输入金额。",
      alertErrorEnterQuantity: "请输入数量。",
      alertErrorInvalidAddress: "地址无效。",
      alertErrorInvalidAmount: "金额无效。",
      alertErrorInvalidDate: "日期无效。",
      alertErrorTransactionFailed: "交易失败。请稍后再试。",
      alertErrorMaximumStaking: "总共只允许3个质押，只允许一个活跃的质押。",
      alertErrorSetPresaleValue: "请输入要设置的值。",
      alertErrorSetPresaleMinPurchase01: "最小数量超过了当前输入的最大数量。",
      alertErrorSetPresaleMinPurchase02: "最小数量超过了当前设置的最大数量。",
      alertErrorSetPresaleMaxPurchase01:
        "输入的最大数量小于当前设置的最小数量。",
      alertErrorSetPresaleMaxPurchase02: "输入的最大数量小于最小数量。",
      alertErrorSetPresalePrice: "请输入价格。",
      alertErrorNotMember: "输入的推荐人地址不是会员。",
      alertErrorSetStakingType: "请选择类型。",
      alertErrorSetStakingTotalLimit: "请输入总限制。",
      alertErrorSignUp: "注册过程中发生错误。请稍后再试",
      alertErrorInsufficientBalance: "余额不足。",
      alertErrorMinimumQuantity: "输入的数量小于最小数量。",
      alertErrorMaximumQuantity: "输入的数量大于最大数量。",
      alertErrorTimeOut: "请求超时。请稍后再试。",
      alertErrorNotStakingDate: "质押开始时间(GMT)：2024-04-20 AM 12:00:00",
      alertErrorUnknown: "发生未知错误。请稍后再试。",
      alertErrorAlreadyStaking: "您已经在质押。",
      alertErrorUserNotFound: "找不到用户。",
      alertErrorNotAdmin: "您不是管理员。",
      alertErrorNotDividend: "分红当前未进行中。",
      alertErrorEnterIndex: "请输入编号。",
      alertErrorEndStaking: "当前质押的进度计数已达到最大值。",
      alertErrorNotStakingAddress: "只有批准的地址才能进行1万次质押。",
      alertErrorEnterNameBirth: "请输入姓名和出生日期。",
      alertErrorEnterBirth: "请输入8位数的出生日期。",
      alertErrorNoUnstakeToken: "没有可提取的金额。",
      alertErrorEnterStartDate: "请输入开始日期。",
      alertErrorStartDate: "开始日期必须在当前日期之后。",
      alertErrorRewardCountZero: "奖励数量必须大于0。",
      alertErrorSoldAmountSwap: "There is no remaining swap quantity.",

      // Staking Alert Error Message
      alertErrorClaimOnce: "UTC午夜后一天只能提取一次。",
      // alertErrorOnlyActiveClaim: "要索取奖励，质押必须处于活动状态。",
      alertErrorOnlyActiveClaim: "没有可提取的金额。",
      alertErrorSearchAfterStaking: "质押后可搜索。",
      alertErrorNotEnoughBalance: "余额不足。",
      alertErrorAmountZero: "数量必须大于0。",
      alertErrorInvalidPrice: "价格无效 。",
    },
    ja: {
      // Common Text
      CommonWallet: "ウォレット",
      CommonLockup: "ロックアップ",
      CommonPresale: "プリセール",
      CommonStaking: "ステーキング",
      CommonDividend: "配当",
      CommonSwap: "スワップ",
      CommonAmount: "数量",
      CommonWithdrawAmount: "引き出し金額",
      CommonWithdrawableAmount: "出金予定金額",
      CommonRewardAmount: "報酬金額",
      CommonStartDate: "開始日",
      CommonEndDate: "終了日",
      CommonNoList: "リストがありません。",
      CommonList: "世代",
      CommonReady: "準備",
      CommonStartAt: "開始まで",
      CommonAmkSwapAmount: "AMK スワップ数量",

      // Wallet Text
      CommonMyWallet: "私のウォレット",
      CommonMyAddress: "私のアドレス",
      CommonAddress: "アドレス",
      CommonCopyAddress: "アドレスをコピー",
      CommonShareLink: "リンクを共有",
      CommonSend: "送信",
      CommonMore: "もっと",

      // Lockup Text
      CommonNumber: "番号",
      CommonWithdrawCount: "引き出し回数",
      CommonStatus: "状態",
      CommonLocked: "ロック",
      CommonRelease: "解放",
      CommonStop: "停止",
      CommonLockupIntroTitleText: "AMKをロックして報酬を受け取ります！",
      CommonLockupPlease: "ロックアップを続行してください。",
      CommonLockupCompleted: "ロックアップが完了しました",
      CommonLockupStop: "ロックアップ停止",

      // Presale Text
      CommonBuyToken: "トークン購入",
      CommonMin: "最小",
      CommonMax: "最大",

      // Staking Text
      CommonMyStaking: "私のステーキング",
      CommonType: "タイプ",
      CommonProgress: "進捗",
      CommonCompleted: "完了",
      CommonPaused: "一時停止",
      CommonSlots: "現在 / 制限",
      CommonDuration: "期間",
      CommonDailyPayoutQuantity: "毎日の支払い数量",
      CommonStakingQuantity: "ステーキング数量",
      CommonTotalReward: "総報酬",
      CommonDays: "日",
      CommonTotalAmount: "合計金額",
      CommonReferrerTotalAmount: "紹介者合計金額",
      CommonWithdraw: "引き出し",

      // Dividend Text
      CommonMyDividend: "私の配当",
      CommonDepositedAmkTokens: "総配当プール",
      CommonExpectedProfit: "予想利益",
      CommonJoinDividend: "配当に参加",
      CommonDividendProject: "配当プロジェクト",
      CommonPcmCooling: "PCM冷却空調",
      CommonExpectedDividend: "配当プールステーキング報酬",
      CommonDepositYour:
        "AMKトークンを契約に預け入れ、配当日まで保持すると配当の対象になります。ステーキング報酬を受け取るために参加してください。",
      CommonDividendInfo: "配当情報",
      CommonDividendDesc1: "ステーキングと報酬",
      CommonDividendDesc2: "配当契約に預け入れるとステーキングが進行します。",
      CommonDividendDesc3:
        "ステーキング終了後にステーキング報酬と預け入れ金額を受け取ることができます。",
      CommonDividendDesc4: "配当参加条件",
      CommonDividendDesc5:
        "配当を受け取るためには、名前と生年月日を入力する必要があります。",
      CommonDividendDesc6:
        "名前と生年月日を入力しない場合、配当から除外されます。",
      CommonDividendDesc7:
        "ただし、これはステーキング報酬に影響しません。名前を入力しなくてもステーキング報酬を受け取ることができます。",
      CommonDividendDesc8: "配当参加締め切り",
      CommonDividendDesc9: "配当参加は終了日まで可能です。",
      CommonDividendDesc10:
        "配当受取人は、配当契約に預け入れたユーザーに限定されます。",
      CommonDividendDesc11: "報酬と注意事項",
      CommonDividendDesc12:
        "報酬額は見積もりであり、より多くのユーザーが参加すると報酬額が減少します。",
      CommonDividendDesc13:
        "報酬はステーキング数量と期間に比例して支払われます。",
      CommonDividendDesc14: "その他の情報",
      CommonDividendDesc15:
        "ステーキング中に報酬や預金を引き出すことはできません。",
      CommonDepositAmount: "預金額",
      CommonMyShare: "私のシェア",
      CommonExpectedReward: "予想報酬",
      CommonMyBalance: "私の残高",
      CommonUntilEnd: "終了まで",
      CommonUntilEnd2: "結算完了後に支払います。",
      CommonDividendIntro1: "Anemoi AMKはセキュリティトークンです。",
      CommonDividendIntro2: "将来の配当支払いのためには、",
      CommonDividendIntro3: "名前（実名）と生年月日を入力する必要があります。",
      CommonDividendIntro4:
        "配当を受け取るためには正確な情報を入力する必要があります。",
      CommonDividendIntro5:
        "配当を受け取りたくない場合は、入力せずに確認ボタンを押してください。",
      CommonDividendIntro6: "名前（実名）を入力してください。",
      CommonDividendName: "名前（実名）を入力してください。",
      CommonDividendBirth: "生年月日（8桁）を入力してください。",
      CommonDividendCheck: "再表示しない",
      CommonDividendMax: "最大",
      CommonDividendEndDate: "配当終了日",
      CommonDividendInputEndDate: "配当終了日を入力してください。",
      CommonDividendNotice: "お知らせ",
      CommonDividendEnded: "現在進行中の配当がありません。",
      CommonDividendIng: "進行中",
      CommonDividendBlacklistedAddress: "出金が制限されたウォレットです。基金にお問い合わせください。",

      // Common Set Text
      CommonSetLockup: "ロックアップ設定",
      CommonSetPresale: "プリセール設定",
      CommonSetStaking: "ステーキング設定",
      CommonReferrerRegistration: "紹介者登録",
      CommonReferrer: "紹介者",
      CommonConfirm: "確認",
      CommonCancel: "キャンセル",

      // Staking Information
      stakingInformation: "ステーキング情報",
      stakingInfoTitle01: "手数料開示",
      stakingInfoText01: "請求時に5％の手数料が差し引かれます。",
      stakingInfoTitle02: "ステーキング制限",
      stakingInfoText02:
        "アクティブなステーキングは1つのみ許可されます。 解除後、最大3つの追加ステーキングが追加できます。",
      stakingInfoTitle03: "会員要件",
      stakingInfoText03: "登録後にステーキングが可能です。",
      stakingInfoTitle04: "報酬構造",
      stakingInfoText04:
        "報酬は360日間毎日分配されます。 紹介報酬の概念は報酬の量を増やすのではなく、早期に報酬を請求することです。",
      stakingInfoTitle05: "紹介報酬",
      stakingInfoText05:
        "あなたを紹介した人がステーキングすると、以下の報酬を受け取ることができます。",
      stakingInfoTitle06: "1次紹介",
      stakingInfoText06: "ステーキング金額の7％",
      stakingInfoTitle07: "2次紹介",
      stakingInfoText07: "ステーキング金額の5％",
      stakingInfoTitle08: "3次紹介",
      stakingInfoText08: "ステーキング金額の3％",
      stakingInfoTitle09: "4次紹介",
      stakingInfoText09: "ステーキング金額の2％",
      stakingInfoTitle10: "5次紹介",
      stakingInfoText10: "ステーキング金額の1％",
      stakingInfoTitle11: "6次紹介",
      stakingInfoText11: "ステーキング金額の1％",
      stakingInfoTitle12: "7次紹介",
      stakingInfoText12: "ステーキング金額の1％",

      // Page State Wallet
      walletWrapTextFront: "あなたの",
      walletWrapTextBack: "でより多くの価値を発見する",
      walletWrapTextPoint: "AMK",

      // Page State Presale
      presaleWrapTextFrontStart: "プリセールが",
      presaleWrapTextPointStart: "終了しました",
      presaleWrapTextFrontLive: "プリセールが",
      presaleWrapTextPointLive: "終了しました",

      // Page State Swap
      swapIntroTitleText: "AMK SWAP",
      swapIntroText1: "只有在有限的时间内才能使用",
      swapIntroText2: "特殊交换!",
      swapIntroText3: "交換した数量は第2の配当契約に入金する必要があります。",
      swapReadyText: "AMK SWAP正在准备中。",
      swapWhiteListText: "只有在白名单上注册的钱包才能参与。",
      swapCaptionText:
        "最初の配当契約の解放は3月8日から3日間続きます。この期間後は引き出しができなくなりますので、必ず期日までに引き出しを完了してください。",
      swapNoShow: "再表示しない",

      // Alert Success Message
      alertSuccessChangeAdmin: "管理者が変更されました。",
      alertSuccessAddLockup: "ロックアップが追加されました。",
      alertSuccessSetFeeRecipient: "手数料受取人が変更されました。",
      alertSuccessSetRecipient: "受取人が変更されました。",
      alertSuccessSetFeeRate: "手数料率が変更されました。",
      alertSuccessSetPresale: "プリセール設定が完了しました。",
      alertSuccessSetStakingLimit: "ステーキング制限が変更されました。",
      alertSuccessSendAmount: "金額が送信されました。",
      alertSuccessBuyToken: "トークンが購入されました。",
      alertSuccessWithdrawFunds: "資金が引き出されました。",
      alertSuccessWithdrawToken: "トークンが引き出されました。",
      alertSuccessEndPresale: "プリセールが終了しました。",
      alertSuccessStartStaking: "ステーキングが開始されました。",
      alertSuccessLockupStart: "ロックアップが開始されました。",
      alertSuccessLockupRelease: "ロックアップが解除されました。",
      alertSuccessLockupStop: "ロックアップが停止されました。",
      alertSuccessChangeStakingStatus:
        "ステーキングステータスが変更されました。",
      alertSuccessCopyAddress: "アドレスがコピーされました。",
      alertSuccessCopyLink: "リンクがコピーされました。",
      alertSuccessLockupData: "ロックアップデータが変更されました。",
      alertSuccessCompleteDividend: "配当が参加されました。",
      alertSuccessClaimDividend: "報酬が請求されました。",
      alertSuccessEndDividend: "配当終了日が設定されました。",
      alertSuccessEndStaking: "ステーキングが終了しました。",
      alertSuccessRegister: "情報が正常に登録されました。",
      alertSuccessSwapWithdrawAmk: "AMKが引き出されました。",
      alertSuccessSwapWithdrawUsdt: "USDTが引き出されました。",
      alertSuccessEndDate: "終了日が設定されました。",
      alertSuccessPrice: "価格が設定されました。",
      alertSuccessSwapToken: "トークンがスワップされました。",

      // Alert Error Message
      alertErrorConnectWallet: "ウォレットに接続してください。",
      alertErrorUserReject: "ユーザーがリクエストを拒否しました。",
      alertErrorRequestFailed:
        "リクエストに失敗しました。後でもう一度お試しください。",
      alertErrorFeeRate: "手数料率は0から100の間でのみ入力できます。",
      alertErrorChainId:
        "チェーンの変更に失敗しました。後でもう一度お試しください。",
      alertErrorEnterAddress: "アドレスを入力してください。",
      alertErrorEnterAmount: "金額を入力してください。",
      alertErrorEnterQuantity: "数量を入力してください。",
      alertErrorInvalidAddress: "アドレスが無効です。",
      alertErrorInvalidAmount: "金額が無効です。",
      alertErrorInvalidDate: "日付が無効です。",
      alertErrorTransactionFailed:
        "トランザクションに失敗しました。後でもう一度お試しください。",
      alertErrorMaximumStaking:
        "合計3つのステーキングのみが許可され、アクティブなステーキングは1つのみ許可されます。",
      alertErrorSetPresaleValue: "設定する値を入力してください。",
      alertErrorSetPresaleMinPurchase01:
        "最小数量が現在入力された最大数量を超えています。",
      alertErrorSetPresaleMinPurchase02:
        "最小数量が現在設定された最大数量を超えています。",
      alertErrorSetPresaleMaxPurchase01:
        "最大数量が現在入力された最小数量よりも小さいです。",
      alertErrorSetPresaleMaxPurchase02: "最大数量が最小数量よりも小さいです。",
      alertErrorSetPresalePrice: "価格を入力してください。",
      alertErrorNotMember: "入力した紹介者アドレスは会員ではありません。",
      alertErrorSetStakingType: "タイプを選択してください。",
      alertErrorSetStakingTotalLimit: "合計制限を入力してください。",
      alertErrorSignUp:
        "登録中にエラーが発生しました。後でもう一度お試しください",
      alertErrorInsufficientBalance: "残高が不足しています。",
      alertErrorMinimumQuantity: "入力した数量が最小数量よりも少ないです。",
      alertErrorMaximumQuantity: "入力した数量が最大数量よりも多いです。",
      alertErrorTimeOut:
        "リクエストがタイムアウトしました。後でもう一度お試しください。",
      alertErrorNotStakingDate:
        "ステーキング開始時間(GMT)： 2024-04-20 AM 12:00:00",
      alertErrorUnknown:
        "不明なエラーが発生しました。後でもう一度お試しください。",
      alertErrorAlreadyStaking: "すでにステーキング中です。",
      alertErrorUserNotFound: "ユーザーが見つかりません。",
      alertErrorNotAdmin: "あなたは管理者ではありません。",
      alertErrorNotDividend: "現在、配当は進行中ではありません。",
      alertErrorEnterIndex: "番号を入力してください。",
      alertErrorEndStaking:
        "現在のステーキングの進行回数が最大値に達しました。",
      alertErrorNotStakingAddress:
        "承認されたアドレスのみ1万回のステーキングが可能です。",
      alertErrorEnterNameBirth: "名前と生年月日の両方を入力してください。",
      alertErrorEnterBirth: "生年月日を8桁で入力してください。",
      alertErrorNoUnstakeToken: "引き 出し可能な金額がありません。",
      alertErrorEnterStartDate: "開始日を入力してください。",
      alertErrorStartDate: "開始日は現在の日付より後である必要があります。",
      alertErrorRewardCountZero: "報酬数量は0より大きくする必要があります。",
      alertErrorSoldAmountSwap: "現在のスワップ 数量が0です。",

      // Staking Alert Error Message
      alertErrorClaimOnce: "UTC午前0時以降、1日に1回のみ請求できます。",
      // alertErrorOnlyActiveClaim: "報酬を請求するには、ステーキングがアクティブである必要があります。",
      alertErrorOnlyActiveClaim: "引き出す金額がありません。",
      alertErrorSearchAfterStaking: "ステーキング後に検索可能です。",
      alertErrorNotEnoughBalance: "残高が不足しています。",
      alertErrorAmountZero: "数量は0より大きく入力してください。",
      alertErrorInvalidPrice: "価格が無効です。",
    },
  },
});

const axiosInstance = axios.create({
  // 기본 헤더 설정
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: "https://api.anemoi.finance",
});

const app = createApp(App);
app.config.globalProperties.$axios = axiosInstance;

app.use(pinia).use(router).use(i18n).mount("#app");
